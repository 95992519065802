@use "sass:color"; @use "sass:math"; @use "@/web/client/assets/css/variables" as *; 
article.home-page{
    background: color.adjust($dark, $lightness: 10%);
    color: white;
    display: grid;
    align-items: center;

    .container{
        padding-top: $padding * 2;
        padding-bottom: $padding * 2;

        display: grid;
        gap: $padding * 4;

        @media (max-width: $mobile-breakpoint) {
            gap: $padding * 2;
        }
    }

    h1{
        color: $primary-on-dark;
        font-size: 6rem;
        font-weight: 900;

        @media (max-width: $mobile-breakpoint) {
            font-size: 3rem;
        }
    }

    h2{
        color: $text-on-dark;
        font-weight: normal;
        font-size: 3rem;

        @media (max-width: $mobile-breakpoint) {
            font-size: 1.5rem;
        }
    }

    a{
        font-size: 1.5rem;
        line-height: $padding * 2;

        @media (max-width: $mobile-breakpoint) {
            font-size: 1rem;
            line-height: 1;
        }
    }
}
